import jQuery from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import SmoothScroll from 'smooth-scroll';
import magnificPopup from 'magnific-popup';

window.$ = window.jQuery = jQuery;
window.bootstrap = bootstrap;
window.popper = popper;

// $('.video-pop-up').magnificPopup({
//   type: 'iframe',
//   mainClass: 'mfp-fade',
//   removalDelay: 160,
//   preloader: false,
//   fixedContentPos: false
// });

// $.magnificPopup.open({
//   items: {
//     src: 'reshed.webp'
//   },
//   type: 'image'
// });

// $('.img-pop-up').magnificPopup({
//   type: 'image',
//   gallery: {
//       enabled: true
//   }
// });

$(window).on('scroll', function () {
  if ($(window).scrollTop() < 300) {
    $('.site-navigation').removeClass('sticky_header');
  } else {
      $('.site-navigation').addClass('sticky_header');
  }
});

var scroll = new SmoothScroll('a[data-scroll]');

$( '.hamburger-menu' ).on( 'click', function() {
  $(this).toggleClass('open');
  $('.site-navigation').toggleClass('show');
});

var pathname = location.pathname.split("/");
console.log(pathname);
$('.site-navigation a[href="' + pathname[pathname.length-1] + '"]').parent().addClass('current-menu-item');

// document.addEventListener("DOMContentLoaded", function() {
//   var links = [].slice.call(document.querySelectorAll('[data-external]'));

//   links.map(link => {
//      link.addEventListener('click', function(e){
//         e.preventDefault();
//         var url = link.getAttribute('href');
//         fbq('track', 'InitiateCheckout');
//         gtag('event', 'sign_up', {
//            'event_callback': function(){
//               window.open(url);
//             }
//         });
//      });
//   })

//   console.log(links);
// });